import {AuthorizationStrategy, IdentificationStrategy} from "lib/types/security"
import eventBus from "lib/vue/eventBus"
import {ACCESS_DENIED, ACCESS_RESTORED, AUTHORIZATION_FAILED, ACCESS_GRANTED, IDENTIFICATION_FAILED, ACCESS_REVOKED} from "lib/vue/events"
import HttpStatus from "lib/request/status"
import RequestError from "lib/request/RequestError"
import {throttle} from "lodash-es"
import {StorageOptions} from "lib/types/storage"

const DEFAULT_KEY = "session"

interface SessionState {
	token: unknown
	identified: boolean
	identifier: string | undefined
	impersonation: boolean
}

export default class Session<C> {
	private readonly options: StorageOptions
	private readonly identification: IdentificationStrategy<C>
	private readonly authorization: AuthorizationStrategy
	private readonly timeout: number
	private readonly introspect: boolean
	private readonly key: string
	private state?: SessionState
	private intervalIsCreated: boolean

	constructor(
		options: StorageOptions,
		identification: IdentificationStrategy<C>,
		authorization: AuthorizationStrategy,
		timeout: number = Infinity,
		introspect: boolean = false
	) {
		this.options = options
		this.identification = identification
		this.authorization = authorization
		this.timeout = timeout
		this.introspect = introspect
		this.key = options.key || DEFAULT_KEY
		this.state = options.storage.retrieve(this.key)
		this.intervalIsCreated = false

		if (this.state) {
			authorization.authorize(this.state.token)
			if (this.state.identified) {
				identification.identifier = this.state.identifier
			}
		}
	}

	/**
	 * Verifies that the user is authenticated for the roles.
	 */
	async verify(roles?: ReadonlyArray<string>): Promise<void> {
		if (this.authorization.isAuthorized && this.identification.isIdentified) {
			if (roles && roles.length && !this.authorization.isAuthorizedAny(roles)) {
				eventBus.emit(ACCESS_DENIED)
			} else {
				eventBus.emit(ACCESS_RESTORED)
				if (this.state && !this.state.impersonation) {
					if (this.timeout < Infinity) {
						await this.introspectSession()
					}
				}
			}
		} else {
			eventBus.emit(AUTHORIZATION_FAILED)
		}
	}

	async login(credentials: C): Promise<void> {
		try {
			const token = await this.identification.identify(credentials)
			const success = this.authorization.authorize(token)
			if (success) {
				this.store(token, false)
				eventBus.emit(ACCESS_GRANTED, this.identification.identifier)
				if (this.timeout < Infinity) {
					this.watch()
				}
			} else {
				eventBus.emit(ACCESS_DENIED)
			}
		} catch (error) {
			if (error instanceof RequestError) {
				switch (error.response.status) {
					case HttpStatus.FORBIDDEN:
						eventBus.emit(ACCESS_DENIED)
						break
					case HttpStatus.UNAUTHORIZED:
					default:
						eventBus.emit(IDENTIFICATION_FAILED)
						break
				}
			} else {
				eventBus.emit(IDENTIFICATION_FAILED)
			}
		}
	}

	impersonate(identifier: string | undefined, token: unknown): boolean {
		if (this.authorization.authorize(token)) {
			this.identification.identifier = identifier
			this.store(token, true)
			return true
		}
		return false
	}

	async logout(): Promise<void> {
		if (this.authorization.isAuthorized) {
			this.authorization.unauthorize()
			this.options.storage.discard(this.key)
			await this.identification.unidentify()
			eventBus.emit(ACCESS_REVOKED)
		}
	}

	async introspectSession(): Promise<void> {
		if (this.authorization.isAuthorized) {
			const validSession = await this.identification.introspectSession()
			if (!validSession) {
				await this.logout()
			} else {
				this.watch()
			}
		}
	}

	/**
	 * Forgets the identity of the user, but keeps the token. After this, the session is no longer valid
	 * but secured calls to the backend are still possible. This can be useful if the user is unknown.
	 */
	async forget() {
		await this.identification.unidentify()
		this.store(this.state?.token, false)
	}

	private store(token: unknown, impersonation: boolean) {
		this.state = {
			token,
			identified: this.identification.isIdentified,
			identifier: this.identification.identifier,
			impersonation
		}
		this.options.storage.store(this.key, this.state)
	}

	private watch() {
		if (this.intervalIsCreated) return
		this.intervalIsCreated = true

		if (!process.env.SERVER) {
			let timer: NodeJS.Timeout | null = null
			const startCheck = () => {
				if (timer) clearInterval(timer)
				timer = setInterval(async () => {
					if (this.introspect) {
						await this.introspectSession()
					} else {
						await this.logout()
					}
				}, this.timeout * 60 * 1000)
			};

			startCheck()

			const resetCheck = throttle(() => {
				if (timer) clearInterval(timer)
				startCheck()
			}, 1000, { leading: true })

			for (const event of ["mousedown", "keydown", "touchstart"]) {
				document.body.addEventListener(event, (e) => {
					const event = e as MouseEvent
					if (!event.altKey && !event.ctrlKey && !event.metaKey) {
						resetCheck()
					}
				}, { capture: true })
			}
		}
	}
}
