<template>
	<div v-if="selectedMutation">
		<div v-if="!requestSend" class="box">
			<div class="pagewrapper__heading">
				<h1>{{ formTexts.title }}</h1>
			</div>

			<div v-show="selectedMutationBlocked" class="box-content">
				<accessible-error
					:show-error="showMessagePremiumIncreaseNotAllowed" 
					error-message="Op basis van (fiscale) voorwaarden is voor deze polis helaas geen extra storting mogelijk." 
				/>
				<accessible-error
					:show-error="showMessageExtensionNotAllowed" 
					error-message="Op basis van (fiscale) voorwaarden kan de einddatum voor deze polis helaas niet worden uitgesteld." 
				/>
				<accessible-error
					:show-error="showMessageHasWisselDatumClause" 
					error-message="Op deze polis is een wisseldatum van toepassing. Dit betekent dat wij de polis jaarlijks automatisch verlengen tot het jaar waarin de verzekerde de 70 jarige leeftijd bereikt." 
				/>
				<accessible-error
					:show-error="showMessageNonSmokerDiscountAlreadyApplied" 
					error-message="Voor deze verzekering is de &quot;niet-rokerskorting&quot; al verwerkt." 
				/>
				<accessible-error
					:show-error="showMessageNonSmokerDiscountNotApplicable" 
					error-message="Op deze verzekering wordt geen onderscheid gemaakt tussen wel en niet roken." 
				/>
			</div>

			<div v-if="!selectedMutationBlocked" class="box-content">
				<span v-if="selectedMutation.requestType === RequestType.OFFER && !offerFase">
					<p v-if="formTexts.introList && formTexts.introList.length">
						<ul><li v-for="(text, i) in formTexts.introList" :key="i">{{ text }}</li></ul>
					</p>
					<p v-html="formTexts.intro" ref="introText"></p>

					<p><strong>Stap 1:</strong> {{ formTexts.step1 }}</p>
					<span class="mutation-buttons">
						<a href="#" @click.prevent="changeOfferFase($event, OfferFase.REQUEST)">{{ offerFaseTexts[OfferFase.REQUEST] }}</a>
					</span>
					<p><strong>Stap 2:</strong> {{ applicationTexts.offerAgreed }} Upload dan de ondertekende offerte.</p>
					<span class="mutation-buttons">
						<a href="#" @click.prevent="changeOfferFase($event, OfferFase.APPROVED)">{{ offerFaseTexts[OfferFase.APPROVED] }}</a>
					</span>
				</span>

				<span v-else-if="isSelfService && selectedMutation.mutationType === MutationType.BANKACCOUNT">
					<p v-if="formTexts.introList && formTexts.introList.length">
						<ul><li v-for="(text, i) in formTexts.introList" :key="i">{{ text }}</li></ul>
					</p>
					<div class="premium-contributor">
						<p>Blijft dezelfde persoon de premie betalen?</p>
						<input 
							type="radio" 
							id="yes" 
							name="samePremiumContributor" 
							:value="true"
							v-model="samePremiumContributor" 
							@change="$emit('updateSamePremiumContributor', { event: $event, label: 'Blijft dezelfde persoon de premie betalen?' })"
						>
						<label for="yes">Ja</label>
						<input 
							type="radio" 
							id="no" 
							name="samePremiumContributor" 
							:value="false" 
							v-model="samePremiumContributor"
							@change="$emit('updateSamePremiumContributor', { event: $event, label: 'Blijft dezelfde persoon de premie betalen?' })"
						>
						<label for="no">Nee</label>
					</div>
					<div v-show="samePremiumContributor">
						<p v-html="formTexts.intro" ref="introText"></p>
						<protected-button v-if="isSelfService" :protect="false" title="Mail het formulier" :showProtectedText="showProtectedText" />
					</div>
					<div v-show="samePremiumContributor === false">
						<p>U kunt dan <a href="#" @click.prevent="changeBeneficiary">hier</a> de nieuwe premiebetaler doorgeven.</p>
					</div>
				</span>

				<span v-else-if="selectedMutation.requestType === RequestType.PDFUPLOAD && selectedMutation.mutationType !== MutationType.PAYOUT_BENEFICIARY_CONSENT">
					<span v-if="!showUploadForm && formTexts.paperForm">
						<p v-if="formTexts.introList && formTexts.introList.length">
							<ul><li v-for="(text, i) in formTexts.introList" :key="i">{{ text }}</li></ul>
						</p>
						<p v-html="formTexts.intro" ref="introText"></p>

						<template v-if="!formTexts.paperFormUploadOnly">
							<p ><strong>Stap 1:</strong> Download het formulier "{{ formTexts.paperForm.title }}".</p>
							<span class="mutation-buttons">
								<a :href="formTexts.paperForm.url" target="_blank" @click="downloadFormButtonClicked">{{ formTexts.paperForm.title }} (pdf)</a>
							</span>
							<p><strong>Stap 2:</strong> Upload {{ applicationTexts.preset }} ingevulde en ondertekende formulier "{{ formTexts.paperForm.title }}"</p>
						</template>

						<span class="mutation-buttons">
							<a href="#" @click.prevent="visitUploadForm">Upload formulier</a>
						</span>
					</span>
					<p v-else-if="formTexts.paperForm">
						Upload hier {{ applicationTexts.preset }} ingevulde en ondertekende formulier "<a :href="formTexts.paperForm.url" target="_blank" @click="downloadFormLinkClicked">{{ formTexts.paperForm.title }} (pdf)</a>".
					</p>
				</span>

				<span v-else-if="selectedMutation.mutationType !== MutationType.PAYOUT_BENEFICIARY_CONSENT">
					<p v-if="formTexts.introList && formTexts.introList.length">
						<ul><li v-for="(text, i) in formTexts.introList" :key="i">{{ text }}</li></ul>
					</p>
					<p v-html="formTexts.intro" ref="introText"></p>
					<protected-button v-if="isSelfService" :protect="false" title="Mail het formulier" :showProtectedText="showProtectedText" />
				</span>

				<div v-if="showForm">
					<div v-if="policyList && policyList.length">
						<label>Polissen:</label>
						<select v-model="selectedPolicyNumber">
							<option v-for="policy in policyList" :key="policy.policyIdentifier" :value="policy.policyIdentifier">{{ policy.policyIdentifier }} - {{ policy.product }}</option>
						</select>
					</div>
					<div v-else>
						<p><strong>Polisnummer</strong> {{ policyData.policyIdentifier }} <span class="box-product"><strong>Product</strong> {{ policyData.productName }}</span></p>
					</div>

					<span v-if="formtype === FormType.CONTACT && contactFormSubjects">
						<label>Onderwerp *</label>
						<select v-model="subject">
							<option v-for="subject in contactFormSubjects" :key="subject.value" :value="subject.value">{{ subject.label }}</option>
						</select>
					</span>

					<span v-if="selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT">
						<p><strong>Onderwerp</strong> Uw levensverzekering bereikt de einddatum </p>
					</span>

					<div v-if="isValidSubject || formtype === FormType.POLICY_CHANGE">
						<span v-if="offerFase === OfferFase.REQUEST">
							<extra-deposit 
								v-if="selectedMutation.mutationType === MutationType.EXTRA_DEPOSIT" 
								@changedMutationFields="changedMutationFields" 
								@focusDecimalInput="$emit('focusExtraDepositDecimalInput', $event)"
								@updatePremiumReduction="$emit('updateExtraDepositPremiumReduction', $event)"
								@error="$emit('error', $event)"
								:submitted="submitted"
								:clause="policyData.clause"
							/>
							<delete-insured 
								v-else-if="selectedMutation.mutationType === MutationType.DELETE_INSURED" 
								@changedMutationFields="changedMutationFields" 
								@open="$emit('openDeleteInsuredSelectInput', $event)"
								@click="$emit('updateDeleteInsuredSelectInput', $event)"
								@error="$emit('error', $event)"
								:submitted="submitted" 
								:policyData="policyData" 
							/>
							<post-pone-end-date 
								v-else-if="selectedMutation.mutationType === MutationType.POSTPONE_ENDDATE" 
								@changedMutationFields="changedMutationFields"
								@focus="$emit('focusPostponeEndDate', $event)"
								@error="$emit('error', $event)"
								:submitted="submitted" 
								:policyEnddate="policyData.policyEnddate" 
								:insuredDateOfBirth="policyData.insured.insuredDateOfBirth" 
							/>
							<premium-amount 
								v-else-if="selectedMutation.mutationType === MutationType.PREMIUM_AMOUNT"
								@changedMutationFields="changedMutationFields" 
								@focusDecimalInput="$emit('focusPremiumAmountDecimalInput', $event)"
								@openSelectInput="$emit('openPremiumAmountSelectInput', $event)"
								@clickSelectInput="$emit('updatePremiumAmountSelectInput', $event)"
								:submitted="submitted" 
								:policyData="policyData" 
							/>
						</span>

						<collection-via-asr 
							v-if="selectedMutation.mutationType === MutationType.COLLECTION_VIA_ASR"
							@changedMutationFields="changedMutationFields" 
							@changedRequiredDocuments="changedRequiredDocuments" 
							@focusDateInput="$emit('focusCollectionViaASR', $event)"
							@error="$emit('error', $event)"
							:submitted="submitted" 
							:requiredDocuments="requiredDocuments" 
						/>
						<payment-term 
							v-else-if="selectedMutation.mutationType === MutationType.PAYMENT_TERM" 
							@changedMutationFields="changedMutationFields"
							@open="$emit('openPaymentTerm', $event)"
							@click="$emit('updatePaymentTerm', $event)"
							@error="$emit('error', $event)"
							:submitted="submitted" :policyData="policyData" 
						/>
						<mortgage-rate 
							v-else-if="selectedMutation.mutationType === MutationType.MORTGAGE_RATE" 
							@changedMutationFields="changedMutationFields" 
							@focus="$emit('focusMortgageRate', $event)"
							@error="$emit('error', $event)"
							:submitted="submitted" 
						/>

						<message-box 
							@changedMessage="changedMessage" 
							@focus="$emit('focusMessageBox', $event)" 
							@error="$emit('error', $event)"
							:messageTexts="formContentTexts" 
							:submitted="submitted" 
							:mutationType="selectedMutation.mutationType" 
						/>
						<multiple-file-uploader 
							@changedFiles="changedFiles" 
							@focus="$emit('focusMultipleFileUploaded', $event)" 
							@requiredFileChecked="$emit('requiredFileChecked', $event)" 
							@error="$emit('error', $event)" 
							:submitted="submitted" 
							:isSelfService="isSelfService" 
							:requiredDocuments="requiredDocuments" 
						/>
						<customer-contact 
							v-if="isSelfService" 
							@changedContactDetails="changedContactDetails"
							@focusEmail="$emit('focusCustomerEmail', $event)"
							@focusMobilePhone="$emit('focusCustomerMobilePhone', $event)"
							@error="$emit('error', $event)" 
							:submitted="submitted" 
							:customerContactDetails="customerContactDetails" 
						/>
						<intermediary-contact 
							v-else 
							@changedContactDetails="changedContactDetails" 
							:submitted="submitted" 
							:intermediaryContactDetails="intermediaryContactDetails" 
						/>

						<div class="button-holder">
							<accessible-error
								:show-error="!!apiErrorMessage"
								:error-message="apiErrorMessage"
							/>
							<protected-button v-if="isSelfService" :protect="axonImpersonation" title="Versturen">
								<template #button>
									<button v-if="!requestSend" type="button" class="button button--secondary" @click.prevent="submit">Versturen</button>
								</template>
							</protected-button>
							<span v-else>
								<button v-if="!requestSend" type="button" class="button button--secondary" @click.prevent="submit">Versturen</button>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="!isSelfService" class="box">
			<div class="pagewrapper__heading">
				<h1>Bedankt!</h1>
			</div>
			<div class="grey-box padding-horizontal">
				<div class="box-content">
					<p v-html="formContentTexts.send" class="send-text"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import Vue from "vue"
	import Component from "vue-class-component"
	import { Prop, Watch } from "vue-property-decorator"
	import { FormType, Application, MutationType, RequestType, OfferFase } from "./typings/enums"
	import { ProductCode } from "./typings/products"
	import { PolicyData,
				IntermediaryContactDetails,
				CustomerContactDetails,
				FormContentTexts,
				FilesToUpload,
				Mutation, MutationPostData, RequiredDocument, FormTexts, ContactFormSubject, 
				DocumentType} from "./typings/interfaces"
	import { TaskType } from "./typings/tasktypes"
	import { getContactFormSubjects, getMutation } from "./data/formcontent"
	import helpText from "./data/documenthelptexts"
	import { mutationPostData as initialMutationPostData } from "./data/defaults"
	import RequestLoader from "leven-shared-components/RequestLoader.vue"
	import MultipleFileUploader from "leven-shared-components/MultipleFileUploader.vue"
	import MessageBox from "leven-shared-components/MessageBox.vue"
	import ProtectedButton from "leven-shared-components/ProtectedButton.vue"
	import IntermediaryContact from "leven-shared-components/IntermediaryContact.vue"
	import CustomerContact from "leven-shared-components/CustomerContact.vue"
	import ExtraDeposit from "leven-shared-components/mutations/ExtraDeposit.vue"
	import DeleteInsured from "leven-shared-components/mutations/DeleteInsured.vue"
	import CollectionViaAsr from "leven-shared-components/mutations/CollectionViaAsr.vue"
	import PaymentTerm from "leven-shared-components/mutations/PaymentTerm.vue"
	import PostPoneEndDate from "leven-shared-components/mutations/PostPoneEndDate.vue"
	import MortgageRate from "leven-shared-components/mutations/MortgageRate.vue"
	import PremiumAmount from "leven-shared-components/mutations/PremiumAmount.vue"
	import { coPolicyHolderIsNonSmoker, policyHolderIsNonSmoker, policyHolderIsSmoker, showNonSmokerDiscountMutation } from "leven-shared-components/typings/sharedrules"
	import { isString } from "lodash-es"
	import AccessibleError from "./AccessibleError.vue"

	type Callback = (event: Event) => void
	type CallbackTrigger = (toTrigger: string, event: Event) => void

	interface Document {
		title: string
		documentType?: DocumentType
		helpText?: string
		infoComponent?: string
	}

	@Component({
		components: {
			RequestLoader,
			MultipleFileUploader,
			MessageBox,
			ProtectedButton,
			IntermediaryContact,
			CustomerContact,
			ExtraDeposit,
			DeleteInsured,
			CollectionViaAsr,
			PaymentTerm,
			PostPoneEndDate,
			MortgageRate,
			PremiumAmount,
			AccessibleError
		}
	})
	export default class MutationFormHolder extends Vue {
		@Prop({type: Object, required: true}) selectedMutation!: Mutation
		@Prop({type: Boolean, required: true}) requestSend!: boolean
		@Prop({type: Boolean, required: true}) dataLoaded!: boolean
		@Prop({type: Object, required: true}) formContentTexts!: FormContentTexts
		@Prop({type: String, required: true}) formtype!: FormType
		@Prop({type: String, required: true}) application!: Application
		@Prop({type: Object, required: false}) intermediaryContactDetails!: IntermediaryContactDetails
		@Prop({type: Object, required: false}) customerContactDetails!: CustomerContactDetails
		@Prop({type: Object, required: true}) policyData!: PolicyData
		@Prop({type: Array, required: false}) policyList!: Array<any>
		@Prop({type: Boolean, required: false, default: false}) axonImpersonation!: boolean
		@Prop({type: String, required: false}) apiErrorMessage!: string | null

		MutationType = MutationType
		RequestType = RequestType
		FormType = FormType
		OfferFase = OfferFase

		submitted = false
		showUploadForm = false
		offerFase: OfferFase | null = null

		offerFaseTexts = {
			[OfferFase.REQUEST]: "Vraag offerte aan",
			[OfferFase.APPROVED]: "Upload ondertekende offerte"
		}

		subject = ""
		message = ""
		mutation = ""
		contactDetailsString = ""
		extrafield?: string = ""

		selectedPolicyNumber = ""

		filesToUpload: FilesToUpload = {
			listOfDocuments: []
		}
		requiredDocuments: Array<Document> = []
		checkedDocuments: Array<string> = []

		filesValid = true
		messageValid = true
		mutationValid = true
		contactDetailsValid = false
		showProtectedText = false
		samePremiumContributor: boolean | null = null

		showMessagePremiumIncreaseNotAllowed = false
		showMessageExtensionNotAllowed = false
		showMessageHasWisselDatumClause = false
		showMessageNonSmokerDiscountAlreadyApplied = false
		showMessageNonSmokerDiscountNotApplicable = false

		contactFormSubjects: Array<ContactFormSubject> | null = null

		applicationTexts = {
			offerAgreed: "",
			preset: ""
		}

		get isValidSubject() {
			return this.subject && this.subject !== ""
		}

		get isSelfService() {
			return this.application === Application.SELFSERVICE
		}

		get formTexts() {

			const texts: FormTexts = {
				title: "",
				intro: "",
				introList: [],
				step1: this.selectedMutation[this.application].step1 || "",
				paperForm: this.selectedMutation[this.application].paperForm || undefined,
				paperFormUploadOnly: this.selectedMutation[this.application].paperFormUploadOnly ?? false
			}

			if (this.offerFase) {
				texts.title = `${this.selectedMutation.title} - ${this.offerFaseTexts[this.offerFase]}`
				if (this.selectedMutation[this.application][this.offerFase]!.introConditional) {
					texts.intro = this.selectedMutation[this.application][this.offerFase]!.introConditional(this.policyData)
				} else {
					texts.intro = this.selectedMutation[this.application][this.offerFase]!.intro
				}
				texts.introList = this.selectedMutation[this.application][this.offerFase]!.introList
			} else {
				texts.title = this.selectedMutation.title
				if (this.selectedMutation[this.application]!.introConditional) {
					texts.intro = this.selectedMutation[this.application]!.introConditional(this.policyData)
				} else {
					texts.intro = this.selectedMutation[this.application]!.intro
				}
				texts.introList = this.selectedMutation[this.application]!.introList
			}

			return texts
		}

		get showForm() {
			return this.selectedMutation.requestType === RequestType.DIGITAL
			|| (this.selectedMutation.requestType === RequestType.PDFUPLOAD && this.showUploadForm)
			|| this.offerFase
			|| this.selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT
		}

		get selectedMutationBlocked() {
			this.showMessagePremiumIncreaseNotAllowed = false
			this.showMessageExtensionNotAllowed = false
			this.showMessageHasWisselDatumClause = false
			this.showMessageNonSmokerDiscountAlreadyApplied = false
			this.showMessageNonSmokerDiscountNotApplicable = false

			if (this.selectedMutation.mutationType === MutationType.EXTRA_DEPOSIT &&
				this.policyData.productCode !== ProductCode.SPH && this.policyData.premiumIncreaseNotAllowed) {
				this.showMessagePremiumIncreaseNotAllowed = true
			}

			if (this.selectedMutation.mutationType === MutationType.POSTPONE_ENDDATE) {

				if (this.policyData.extensionNotAllowed) {
					this.showMessageExtensionNotAllowed = true
				}

				if (this.policyData.hasWisselDatumClause) {
					this.showMessageHasWisselDatumClause = true
				}
			}

			if (this.selectedMutation.mutationType === MutationType.NON_SMOKER_DISCOUNT) {

				if (!showNonSmokerDiscountMutation(this.policyData)) {

					if (policyHolderIsNonSmoker(this.policyData) &&
						(this.policyData.coInsured.mortalityBase === undefined || coPolicyHolderIsNonSmoker(this.policyData))) {
						this.showMessageNonSmokerDiscountAlreadyApplied = true
					}

					if (!policyHolderIsSmoker(this.policyData) && !policyHolderIsNonSmoker(this.policyData)) {
						this.showMessageNonSmokerDiscountNotApplicable = true
					}
				}
			}

			return this.showMessagePremiumIncreaseNotAllowed ||
				this.showMessageExtensionNotAllowed ||
				this.showMessageHasWisselDatumClause ||
				this.showMessageNonSmokerDiscountAlreadyApplied ||
				this.showMessageNonSmokerDiscountNotApplicable
		}

		@Watch("policyList", { immediate: true })
		setDefaultPolicy() {
			if (this.policyList && this.policyList.length) {
				this.selectedPolicyNumber = this.policyList[0].policyIdentifier
			}
		}

		changeOfferFase(event: MouseEvent, offerFase: OfferFase) {
			this.offerFase = offerFase
			this.tealiumPageViewEvent(event, offerFase)
		}

		downloadFormButtonClicked(event: MouseEvent) {
			this.$emit("downloadFormButtonClicked", event)
		}

		downloadFormLinkClicked(event: MouseEvent) {
			this.$emit("downloadFormLinkClicked", event)
		}

		visitUploadForm(event: MouseEvent) {
			this.showUploadForm = true
			this.$emit("uploadFormClicked", event)
			this.tealiumPageViewEvent(event)
		}

		@Watch("offerFase")
		changedOfferFase() {
			if (this.offerFase && this.offerFase === OfferFase.REQUEST) {
				this.requiredDocuments = []
			} else {
				if (this.selectedMutation.requiredDocuments) {
					this.populateRequiredDocuments(this.selectedMutation.requiredDocuments)
				}
			}
		}

		populateRequiredDocuments(requiredDocuments: Array<RequiredDocument>) {

			const documents: Array<Document> = []

			if (this.policyData.coPolicyHolderExists) {
				if (this.isSelfService) {
					if (this.policyData.isPolicyHolderLoggedIn) {
						requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "COPOLICYHOLDER_LOGGEDIN")
					} else {
						requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "POLICYHOLDER_LOGGEDIN")
					}
				}
			} else {
				requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "POLICYHOLDER_LOGGEDIN")

				if (this.isSelfService) {
					requiredDocuments = requiredDocuments.filter(document => document.documentCondition !== "COPOLICYHOLDER_LOGGEDIN")
				}
			}

			if (this.isSelfService) {
				requiredDocuments = requiredDocuments.filter(document => document.documentEnvironment !== Application.EXTRANET)
			} else {
				requiredDocuments = requiredDocuments.filter(document => document.documentEnvironment !== Application.SELFSERVICE)
			}

			for (const requiredDocument of requiredDocuments) {

				const document: Document = {
					title: "",
					documentType: requiredDocument.documentType,
					helpText: undefined,
					infoComponent: undefined
				}

				if (this.policyData.coPolicyHolderExists) {
					if (requiredDocument.documentTitleMultiple) {
						document.title = requiredDocument.documentTitleMultiple
					} else {
						document.title = requiredDocument.documentTitle.replace("{policyHolderName}", this.policyData.policyHolder.fullName!)
											.replace("{coPolicyHolderName}", this.policyData.coPolicyHolder.fullName!)
					}
				} else {
					document.title = requiredDocument.documentTitle.replace("{policyHolderName}", this.policyData.policyHolder.fullName!)
				}

				if (document.title !== "") {
					document.helpText = requiredDocument.helpTextId ? helpText(requiredDocument.helpTextId)?.text : undefined
					document.infoComponent = requiredDocument.helpTextId ? helpText(requiredDocument.helpTextId)?.infoComponent : undefined
					documents.push(document)
				}
			}

			this.requiredDocuments = documents
		}

		@Watch("selectedMutation", {deep: true, immediate: true})
		checkRequiredDocuments() {
			if (this.selectedMutation) {
				const requiredDocuments = this.selectedMutation.requiredDocuments

				if (requiredDocuments && this.selectedMutation.requestType !== RequestType.OFFER) {
					this.populateRequiredDocuments(requiredDocuments)
				}
			}
		}

		retrieveParamOrCookie(searchParams: URLSearchParams, queryKey: string, sessionKey: string): string {
			const queryItem = searchParams.get(queryKey)
			if(queryItem) {
				return queryItem
			} else {
				const sessionItem = sessionStorage.getItem(sessionKey)
				if(sessionItem) {
					sessionStorage.removeItem(sessionKey)
					return sessionItem
				}
			}
			return ""
		}

		created() {
			const params = new URLSearchParams(window.location.search.substring(1))
			
			this.selectedPolicyNumber = this.retrieveParamOrCookie(params, "policyNumber", "mutation_policyNumber")
			this.subject = this.retrieveParamOrCookie(params, "subject", "mutation_subject")
		}

		async mounted() {
			await Vue.nextTick()
			this.contactFormSubjects = getContactFormSubjects(this.application)

			this.createListener("#linkToOtherMutation", this.otherMutation)
			this.createListener("#paymentSettingsLink", this.goToPaymentSettings)
			this.createListener("#linkToWidget", this.toWidget)
			this.createListener("#linkToPremiumContributorForm", (event: Event) => this.downloadFormButtonClicked(event as MouseEvent))

			if (this.isSelfService) {
				this.createListener("#linkToProfilePage", this.toProfileTab)
				this.createListener("#linkToChangeLogin", this.triggerEmit, "goToChangeLogin")
				this.createListener("#linkToRelativesDesk", this.triggerEmit, "goToRelativesDesk")
				this.createListener("#mailBankAccountForm", this.emailBankAccountForm)

				this.applicationTexts.offerAgreed = "Gaat u akkoord?"
				this.applicationTexts.preset = "uw"
			} else {
				this.applicationTexts.offerAgreed = "Akkoord?"
				this.applicationTexts.preset = "het"
			}
		}

		createListener(linkID: string, callBack: Callback | CallbackTrigger, toTrigger?: string) {
			const link = this.$refs.introText ? (this.$refs.introText as HTMLElement).querySelector(linkID) as HTMLElement : undefined

			if (toTrigger) {
				link?.addEventListener("click", callBack.bind(this, toTrigger), false)
			} else {
				link?.addEventListener("click", callBack.bind(this), false)
			}

		}

		tealiumPageViewEvent(event: MouseEvent | string, offerFase?: OfferFase) {
			if (this.isSelfService) {
				let subTabName = isString(event) ? event : (event.target! as Element).textContent
				if (offerFase === OfferFase.APPROVED) {
					subTabName = 'Upload offerte'
				}
				this.$emit('tealiumPageView', { mutation: this.selectedMutation, subTabName, offerFase })
			}
		}

		otherMutation(event: Event) {
			event.preventDefault()
			this.changeMutation(getMutation(MutationType.OTHER)!)
		}

		goToPaymentSettings(event: Event) {
			this.$emit('goToPaymentSettings', event)
		}

		toProfileTab(event: Event) {
			event.preventDefault()
			this.$emit('goToProfilePage', event)
		}

		toWidget(event: Event) {
			event.preventDefault()
			this.$emit("goToWidget", event)
		}

		triggerEmit(toTrigger: string, event: Event) {
			event.preventDefault()
			this.$emit(toTrigger, event)
		}

		emailBankAccountForm(event: Event) {
			this.checkAxonImpersonation(event)
			this.$emit('emailBankAccountFormClicked', event)
		}
		
		checkAxonImpersonation(event: Event) {
			if (this.axonImpersonation) {
				event.preventDefault()
				this.showProtectedText = true
			}
		}

		changeMutation(mutation: Mutation) {
			this.submitted = false
			this.$emit("selectMutation", mutation)
		}

		changedFiles(fileData: {filesToUpload: FilesToUpload, filesValid: boolean, checkedDocuments: Array<string> }) {
			this.filesToUpload = fileData.filesToUpload
			this.filesValid = fileData.filesValid
			this.checkedDocuments = fileData.checkedDocuments
		}

		changedMessage(messageData: {message: string, messageValid: boolean}) {
			this.message = messageData.message
			this.messageValid = messageData.messageValid
		}

		changedMutationFields(mutationData: {mutation: string, mutationValid: boolean}) {
			this.mutation = mutationData.mutation
			this.mutationValid = mutationData.mutationValid
		}

		changedContactDetails(contactData: {contactDetailsString: string, contactDetailsValid: boolean, customerContactDetails?: CustomerContactDetails}) {
			this.contactDetailsString = contactData.contactDetailsString
			this.contactDetailsValid = contactData.contactDetailsValid

			if (contactData.contactDetailsValid && contactData.customerContactDetails) {
				this.$emit("updateCustomerBasicData", contactData.customerContactDetails)
			}
		}

		changedRequiredDocuments(documentData: Array<Document>) {
			this.requiredDocuments = documentData
		}

		get validForm() {
			return this.messageValid && this.filesValid && this.contactDetailsValid && this.mutationValid
		}

		getSubject() {
			if (this.formtype === FormType.CONTACT && this.contactFormSubjects) {
				return this.contactFormSubjects.find(item => item.value === this.subject)!.label
			} else {
				if (this.selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT) {
					return "Uw levensverzekering bereikt de einddatum"
				} else {
					return this.formTexts.title!
				}
			}
		}

		getTaskType(): TaskType | undefined {
			if (this.formtype === FormType.CONTACT) {
				return this.contactFormSubjects!.find(item => item.value === this.subject)!.taskTypeRequest
			} else {
				if (this.selectedMutation.mutationType === MutationType.PAYOUT_BENEFICIARY_CONSENT) {
					return TaskType.INFO_EXPIRATIE
				} else {
					if (this.offerFase) {
						if (this.offerFase === OfferFase.REQUEST) {
							return this.selectedMutation.taskTypeRequest
						} else {
							return this.selectedMutation.taskTypeApproved
						}
					} else {
						return this.selectedMutation.taskTypeRequest
					}
				}
			}
		}

		getMessage() {
			if (this.message !== "") {
				return `${this.formContentTexts!.messageLabel}:\n${this.message}\n\n`
			} else {
				return ""
			}
		}

		getMutation() {
			if (this.mutation !== "") {
				return `${this.mutation}\n\n`
			} else {
				return ""
			}
		}

		getCheckedDocuments() {
			let documentString = ""

			if (this.checkedDocuments.length) {
				for (const document of this.checkedDocuments) {
					documentString = `${documentString}${document}\n`
				}
				return `Bijgevoegde documenten:\n${documentString}\n`
			} else if (this.filesToUpload.listOfDocuments.length) {
				const listOfAllDocumentNames = this.filesToUpload.listOfDocuments.map(doc => doc.fileName)
				for (const fileName of listOfAllDocumentNames) {
					documentString = `${documentString}${fileName}\n`
				}
				return `Bijgevoegde documenten:\n${documentString}\n`
			} else {
				return ""
			}
		}

		changeBeneficiary(event: MouseEvent) {
			const targetLink = `mutatieform.html?mutationType=${MutationType.PREMIUM_CONTRIBUTOR}&returnUrl=mutatieform.html`
			this.$emit('changeBeneficiaryClicked', { event, targetLink })
			location.href = targetLink
		}

		submit(event: MouseEvent) {
			this.submitted = true

			if (this.validForm) {

				const subject = this.getSubject()
				const messageText = this.getMessage()
				const mutationText = this.getMutation()
				const documentText = this.getCheckedDocuments()
				const taskType = this.getTaskType()

				let mutationPostData: MutationPostData = {
					subject,
					message: `${mutationText}${messageText}${documentText}${this.contactDetailsString}`,
					policyId: this.selectedPolicyNumber !== "" ? this.selectedPolicyNumber : this.policyData.policyIdentifier,
					listOfDocuments: this.filesToUpload.listOfDocuments.length ? this.filesToUpload : null,
					taskType
				}

				this.$emit("sendFormData", mutationPostData)

				this.submitted = false
				mutationPostData = initialMutationPostData

				this.$emit('submitClicked', { event, eventType: 'primary' })
			}
		}
	}
</script>

<style>
	.premium-contributor {
		padding-bottom: 20px;
	}

	.info-box {
		padding: 12px;
	}

	.info-box.bank-account ul {
		margin-top: 0;
		margin-bottom: 0;
	}

	.info-box.premium-contributor {
		padding: 12px;
	}

	.info-box.premium-contributor ul {
		margin-bottom: 0;
	}
</style>
