declare const config: {
	iWelcomeLoginUrl: string
	iWelcomeLogoutUrl: string
	apiRootUrl: string
	clientScript: string
	customerEnvironmentExtId: string
	ssoTokenUrl: string
	myUserDataUrl: string
	myPaymentDataUrl: string
	myLoginCredentialsUrl: string
	myAccountUrl: string
	contentUrl: string
	jwtTokenUrl: string
	impersonateTokenUrl: string
	introSpectUrl: string
	publicSiteUrl: string
	publicCorporateUrl: string
	aegonDocumentsServiceUrl: string
	sessionTimeout: number
	tealium: {
		account: string
		profile: string
		env: string
	}
}

if (process.env.SERVER) {
	global.config = {
		iWelcomeLoginUrl: "",
		iWelcomeLogoutUrl: "",
		apiRootUrl: "",
		clientScript: "",
		customerEnvironmentExtId: "",
		ssoTokenUrl: "",
		myUserDataUrl: "",
		myPaymentDataUrl: "",
		myLoginCredentialsUrl: "",
		myAccountUrl: "",
		contentUrl: "",
		jwtTokenUrl: "",
		impersonateTokenUrl: "",
		introSpectUrl: "",
		publicSiteUrl: "",
		publicCorporateUrl: "",
		aegonDocumentsServiceUrl: "",
		sessionTimeout: 0,
		tealium: {
			account: "",
			profile: "",
			env: ""
		}
	}
}

export const iWelcomeLoginUrl = config.iWelcomeLoginUrl
export const iWelcomeLogoutUrl = config.iWelcomeLogoutUrl
export const loginUrl = `${config.apiRootUrl}/auth/token/credential`
export const restApiRootUrl = `${config.apiRootUrl}/rest/authenticated`
export const restApiAnonymousUrl = `${config.apiRootUrl}/rest/anonymous`
export const customerEnvironmentExtId = config.customerEnvironmentExtId
export const ssoTokenUrl = config.ssoTokenUrl
export const myUserDataUrl = config.myUserDataUrl
export const myPaymentDataUrl = config.myPaymentDataUrl
export const myLoginCredentialsUrl = config.myLoginCredentialsUrl
export const myAccountUrl = config.myAccountUrl
export const contentUrl = config.contentUrl
export const jwtTokenUrl = config.jwtTokenUrl
export const impersonateTokenUrl = config.impersonateTokenUrl
export const introSpectUrl = config.introSpectUrl
export const sessionTimeout = config.sessionTimeout
export const clientScript = config.clientScript
export const publicSiteUrl = config.publicSiteUrl
export const publicCorporateUrl = config.publicCorporateUrl
export const aegonDocumentsServiceUrl = config.aegonDocumentsServiceUrl
export const tealiumConfig = config.tealium
